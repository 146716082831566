<template>
	<div>
		<div id="homeBox">
			<el-carousel height="615px" style="width: 100%;margin: 0;">
				<el-carousel-item>
					<template style="position: relative;">
						<img src="../static/images/switchBgImg.jpg" class="switchBgStyle">
					</template>
				</el-carousel-item>
				<el-carousel-item>
					<template style="position: relative;">
						<img src="../static/images/switchBgImg2.png" class="switchBgStyle">
					</template>
				</el-carousel-item>
				<el-carousel-item>
					<template style="position: relative;">
						<img src="../static/images/switchBgImg.jpg" class="switchBgStyle">
					</template>
				</el-carousel-item>
			</el-carousel>
		
			<!-- 商城系统分类 -->
			<div style="padding: 40px 0;">
				<div style="font-size: 36px;color: #333;">玖优精选商城系统</div>
				<div style="font-size: 24px;color: #666;margin-top: 10px;">功能全面、操作便捷的电商产品服务</div>
				<div id="shopTypeBox">
					<div :class="typeIndex == 0?'selShopTypeClass':''" @mousemove="typeIndex = 0">
						<img src="../static/images/shopType1.png" alt="">
						<span>微信小程序</span>
					</div>
					<div :class="typeIndex == 1?'selShopTypeClass':''" @mousemove="typeIndex = 1">
						<img src="../static/images/shopType2.png" alt="">
						<span>PC商家后台</span>
					</div>
					<div :class="typeIndex == 2?'selShopTypeClass':''" @mousemove="typeIndex = 2">
						<img src="../static/images/shopType3.png" alt="">
						<span>H5商家后台</span>
					</div>
				</div>
			</div>
			<div>
				<img v-show="typeIndex == 0" class="banner_Box" src="../static/images/wx_banner.png" alt="">
				<img v-show="typeIndex == 1" class="banner_Box" src="../static/images/pc_banner.png" alt="">
				<img v-show="typeIndex == 2" class="banner_Box" src="../static/images/h5_banner.png" alt="">
			</div>
		
			<!-- 多种运营模式 -->
			<div style="position: relative;">
				<img src="../static/images/bgimg1.png" class="bgImgClass" alt="">
				<div class="conBox">
					<div style="width: 612px;">
						<div class="titleBox">
							<span class="xuhaoBox">01</span>
							<div>多种运营模式</div>
						</div>
						<div class="tabBox">
							<div @mousemove="setConType(0,0)" :class="conTypeIndexArr[0] == 0?'selTabClass':''">
								自营模式<span></span>
							</div>
							<div @mousemove="setConType(0,1)" :class="conTypeIndexArr[0] == 1?'selTabClass':''">
								入驻模式<span></span></div>
							<div @mousemove="setConType(0,2)" :class="conTypeIndexArr[0] == 2?'selTabClass':''">
								混合模式<span></span></div>
						</div>
						<div style="text-align: left;">
							<div v-show="conTypeIndexArr[0] == 0" class="con_contentBox">
								<div class="descTitle">建立自己的供应链生态系统</div>
								<div class="descBox">
									自主决定产品的设计、生产、销售和服务等环节，提高产品的竞争力；<br>
									直接面对终端消费者，省去中间环节的利润分配，从而获得更多的利润空间；<br>
									传递自己的品牌理念和价值观，增加品牌的商业价值；<br>
									信誉保障，防止假冒伪劣，增加客户粘性；
								</div>
							</div>
							<div v-show="conTypeIndexArr[0] == 1" class="con_contentBox">
								<div class="descTitle">第三方平台上开设自己的店铺</div>
								<div class="descBox">
									成熟的运营模式，商家只需要简单了解入驻流程和规定，即可上手经营。<br>
									这使得商家能够更快地进入市场，减少前期准备的时间和成本；<br>
									自带流量，商家可以利用平台的品牌效应和流量优势，快速吸引潜在客户，提升销售额；<br>
									节约成本，无需自行维护和推广网站，节省初始团队的人力和时间成本。<br>
									此外，商家还可以利用平台的营销工具和推广资源，提升产品曝光度和销售效果；
								</div>
							</div>
							<div v-show="conTypeIndexArr[0] == 2" class="con_contentBox">
								<div class="descTitle">综合自营模式和入驻模式</div>
								<div class="descBox">
									更加灵活自由；<br>
									自营+分销；<br>
									更好的应对处理问题以提高销售额；
								</div>
							</div>
						</div>
					</div>
					<div style="width: 626px;">
						<img src="../static/images/desc01.png" style="width: 626px;height: 522px" alt="">
					</div>
				</div>
			</div>
		
			<!-- 多维度数据分析 -->
			<div style="position: relative;">
				<img src="../static/images/bgimg2.png" class="bgImgClass" alt="">
				<div class="conBox">
					<div style="width: 626px;">
						<img src="../static/images/desc02.png" style="width: 626px;height: 522px" alt="">
					</div>
					<div style="width: 612px;">
						<div class="titleBox">
							<span class="xuhaoBox" style="right: -110px;">02</span>
							<div>多维度数据分析</div>
						</div>
						<div class="tabBox">
							<div @mousemove="setConType(1,0)" :class="conTypeIndexArr[1] == 0?'selTabClass':''">
								销量数据<span></span></div>
							<div @mousemove="setConType(1,1)" :class="conTypeIndexArr[1] == 1?'selTabClass':''">营业额<span></span>
							</div>
							<div @mousemove="setConType(1,2)" :class="conTypeIndexArr[1] == 2?'selTabClass':''">订单<span></span>
							</div>
						</div>
						<div style="text-align: left;">
							<div v-show="conTypeIndexArr[1] == 0" class="con_contentBox">
								<div class="descTitle">销量数据统计</div>
								<div class="descBox">
									月度商品销量统计图：可查看销售量、退款量以及退货量；<br>
									商品年度销量统计：可查看一年中每个月的情况统计；
								</div>
							</div>
							<div v-show="conTypeIndexArr[1] == 1" class="con_contentBox">
								<div class="descTitle">营业额数据统计</div>
								<div class="descBox">
									总营业额统计图：可查看迄今为止的总营业额；
								</div>
							</div>
							<div v-show="conTypeIndexArr[1] == 2" class="con_contentBox">
								<div class="descTitle">订单数据</div>
								<div class="descBox">
									总订单数：可查看迄今为止的总订单数量；<br>
									月订单数：可查看当月订单数量；<br>
									订单分类数据：可查看成交率、待支付率和售后率占比；
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<!-- 多种营销工具 -->
			<div style="position: relative;">
				<img src="../static/images/bgimg1.png" class="bgImgClass" alt="">
				<div class="conBox">
					<div style="width: 612px;">
						<div class="titleBox">
							<span class="xuhaoBox">03</span>
							<div>多种营销工具</div>
						</div>
						<div class="tabBox">
							<div @mousemove="setConType(2,0)" :class="conTypeIndexArr[2] == 0?'selTabClass':''">
								裂变拉新<span></span></div>
							<div @mousemove="setConType(2,1)" :class="conTypeIndexArr[2] == 1?'selTabClass':''">
								互动活动<span></span></div>
							<div @mousemove="setConType(2,2)" :class="conTypeIndexArr[2] == 2?'selTabClass':''">
								拓客引流<span></span></div>
						</div>
						<div style="text-align: left;">
							<div v-show="conTypeIndexArr[2] == 0" class="con_contentBox">
								<div class="descTitle">营销活动</div>
								<div class="descBox">
									超值拼团：多人拼单促活；<br>
									限时秒杀：超值低价吸引客户流量和处理存货并增加销量；
								</div>
							</div>
							<div v-show="conTypeIndexArr[2] == 1" class="con_contentBox">
								<div class="descTitle">签到积分活动</div>
								<div class="descBox">
									积分系统：每日签到或者完成任务获得积分；<br>
									积分商城：积分可以直接购买积分商城内的商品；
								</div>
							</div>
							<div v-show="conTypeIndexArr[2] == 2" class="con_contentBox">
								<div class="descTitle">推流分享</div>
								<div class="descBox">
									直播卖货：可开启直播卖货，加入直播折扣等营销方式来吸引客户下单，分享直播间至好友；<br>
									短视频营销：短视频宣发吸引客户兴趣，进行店铺引流，促进订单成交，分享短视频；
								</div>
							</div>
						</div>
					</div>
					<div style="width: 626px;">
						<img src="../static/images/desc01.png" style="width: 626px;height: 522px" alt="">
					</div>
				</div>
			</div>
		
			<!-- 多方位服务体系 -->
			<div style="position: relative;">
				<img src="../static/images/bgimg2.png" class="bgImgClass" alt="">
				<div class="conBox">
					<div style="width: 626px;">
						<img src="../static/images/desc02.png" style="width: 626px;height: 522px" alt="">
					</div>
					<div style="width: 612px;">
						<div class="titleBox">
							<span class="xuhaoBox" style="right: -110px;">04</span>
							<div>多方位服务体系</div>
						</div>
						<div class="tabBox">
							<div @mousemove="setConType(3,0)" :class="conTypeIndexArr[3] == 0?'selTabClass':''">多终端<span></span>
							</div>
							<div @mousemove="setConType(3,1)" :class="conTypeIndexArr[3] == 1?'selTabClass':''">
								专业客服<span></span></div>
							<div @mousemove="setConType(3,2)" :class="conTypeIndexArr[3] == 2?'selTabClass':''">
								定制开发<span></span></div>
						</div>
						<div style="text-align: left;">
							<div v-show="conTypeIndexArr[3] == 0" class="con_contentBox">
								<div class="descTitle">多端联动</div>
								<div class="descBox">
									微信小程序用户端 + H5端商家后台 + PC商家后台
								</div>
							</div>
							<div v-show="conTypeIndexArr[3] == 1" class="con_contentBox">
								<div class="descTitle">专业客服</div>
								<div class="descBox">
									24小时为您服务；<br>
									客户咨询解答：提供详细的信息和解释，帮助客户更好地了解和使用产品；<br>
									投诉处理与解决：准确、迅速地回答客户关于产品或服务的各种问题；<br>
									售后服务与支持：提供售后服务，确保客户在使用产品过程中得到及时的支持和帮助；<br>
									耐心和细心：确保每一个问题都得到妥善处理；
								</div>
							</div>
							<div v-show="conTypeIndexArr[3] == 2" class="con_contentBox">
								<div class="descTitle">1v1专属定制</div>
								<div class="descBox">
									针对性强：适应企业规模发展与产品管理的需求变化；<br>
									使用方便：完全根据使用者的流程所开发，节省经济成本和人力成本；<br>
									效率提高：定制软件具有业务针对性，开发按照企业业务流程来定制，大幅提高效率；
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<!-- 选择我们的理由 -->
			<div style="position: relative;">
				<img src="../static/images/bgimg5.png" class="bgImgClass" alt="">
				<div class="liyouBox">
					<div class="ly_title">选择我们的理由</div>
					<div class="ly_desc">实践积累，经验丰富，专业团队</div>
					<div class="ly_lisBox">
						<div class="ly_lis">
							<img src="../static/images/desc05.png" alt="">
							<div class="ly_contxt">
								<div>多终端</div>
								<div>小程序 + H5端 + PC端</div>
							</div>
						</div>
						<div class="ly_lis">
							<img src="../static/images/desc06.png" alt="">
							<div class="ly_contxt">
								<div>在线客服</div>
								<div>沟通便捷，反馈快</div>
							</div>
						</div>
						<div class="ly_lis">
							<img src="../static/images/desc07.png" alt="">
							<div class="ly_contxt">
								<div>资料丰富</div>
								<div>使用手册以及部署文档等</div>
							</div>
						</div>
						<div class="ly_lis">
							<img src="../static/images/desc08.png" alt="">
							<div class="ly_contxt">
								<div>定制开发</div>
								<div>个性化定制，专属系统</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<!-- 软著证书 -->
			<div style="height: 800px;" id="zhengshuBox">
				<div style="margin-bottom: 20px;">
					<div style="font-size: 36px;color: #333">软著证书</div>
					<div style="font-size: 20px;color: #666;margin-top: 20px;">软著登记证书，实力有保证</div>
				</div>
		
				<el-carousel height="680px" style="width: 85%;margin: 0 auto;" :interval='5000'>
					<el-carousel-item style="background-color: white;" v-for="item in zs_arr">
						<img :src="pic" v-for="(pic,index) in item" style="width: 508px;height: 662px;margin: 0 10rem;" alt="">
					</el-carousel-item>
				</el-carousel>
			</div>
			
			<!-- 公司简介 -->
			<div id='companyContxtBox'>
				<div style="margin-bottom: 20px;margin-top: 40px;">
					<div style="font-size: 36px;color: #333">关于我们</div>
					<div style="font-size: 20px;color: #666;margin-top: 20px;">加入平台，合作共赢</div>
				</div>
				<div id="companyInfoBox"></div>
				<div id="lxwmBox">
					<div style="width: 45%;">
						<div class="lxwm_title">联系我们</div>
						<span class="lxwm_name">
							河北玖御信息科技有限公司
						</span>
						<div class="lxwm_lisBox">
							<img src="../static/images/icon1.png" alt="">
							河北省石家庄市长安区石纺路95号保利广场（北区）H2办公楼1514
						</div>
						<div class="lxwm_lisBox">
							<img src="../static/images/icon2.png" alt="">
							0311-67898888
						</div>
					</div>
					<div style="width: 55%;padding-left: 20px;">
						<baidu-map style="height: 300px;" @ready='handler'></baidu-map>
					</div>
				</div>
			</div>
			
			<!-- 联系弹框 -->
			<contact-dom></contact-dom>
		</div>
	
		<div id='phone_homeBox'>
			<phone-home></phone-home>
		</div>
	</div>
	
</template>

<script>
	import contactDom from '../components/base/contactDom.vue'
	import phoneHome from '../components/home/phoneHome.vue'
	export default {
		name: 'index',
		components: {
			contactDom,
			phoneHome
		},
		data() {
			return {
				typeIndex: 0,
				conTypeIndexArr: [0, 0, 0, 0],
				zs_arr:[
					[require('../static/images/zhengshu2.png'),require('../static/images/zhengshu1.png')],
					[require('../static/images/zhengshu1.png'),require('../static/images/zhengshu3.png')],
					[require('../static/images/zhengshu3.png'),require('../static/images/zhengshu4.png')]
				]
			}
		},
		methods: {
			handler({Bmap,map}){
				this.map = map;
				// 初始化地图,设置中心点坐标
				var point = new BMap.Point(114.5336401462555,38.07448486757763);
				map.centerAndZoom(point, 14);
				// 添加鼠标滚动缩放
				map.enableScrollWheelZoom();
				var marker = new BMap.Marker(point);        // 创建标注   
				map.addOverlay(marker);                     // 将标注添加到地图中
			},
			setConType(index, num) {
				this.$set(this.conTypeIndexArr, index, num)
			}
		}
	}
</script>

<style type="text/css">
	
	#zhengshuBox .el-carousel__button{
		background-color: #ccc !important;
	}
	
</style>

<style lang="scss" scoped>
	
	#phone_homeBox{
		display: none;
	}
	#homeBox{
		min-width: 1600px;display: none;
	}
	
	@media only screen and (max-width:900px){
		#phone_homeBox{
			display: block;
		}
		#homeBox{
			display: none;
		}
	}
	@media only screen and (min-width:900px){
		#phone_homeBox{
			display: none;
		}
		#homeBox{
			display: block;
		}
	}
	
	
	
	// 轮播图
	.switchContxt {
		position: absolute;
		display: flex;
		width: 100%;
		padding: 50px;
		box-sizing: border-box;

		.s_contxt {
			font-size: 42px;
			font-weight: 500;
			color: #333;
			text-align: left;
			margin-top: 10px;
		}

		.s_contxt2 {
			font-size: 24px;
			color: #666;
			text-align: left;
			margin-top: 10px;
		}

		hr {
			height: 6px;
			background-color: #333;
			border: none;
			border-radius: 6px;
			width: 166px;
			margin: 30px 0;
		}
	}

	// 选择系统类型
	#shopTypeBox {
		display: flex;
		justify-content: center;

		div {
			display: flex;
			align-items: center;
			margin: 60px 50px;
			margin-bottom: 20px;
			cursor: pointer;
			padding: 0 40px;
		}

		img {
			width: 64px;
			height: 64px;
			margin-right: 15px;
		}

		.selShopTypeClass {
			background: linear-gradient(90deg, #8DB8FF 0%, #297BFF 100%);
			height: 64px;
			box-sizing: border-box;
			border-radius: 64px;
			color: white;
			box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		}
	}

	// 系统分步介绍
	.conBox {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 80px 0;

		.titleBox {
			font-size: 38px;
			color: #333;
			position: relative;

			.xuhaoBox {
				font-size: 130px;
				color: #DADFE2;
				position: absolute;
				top: -55px;
				left: 110px;
			}

			div {
				z-index: 9;
				position: relative;
			}
		}

		.tabBox {
			display: flex;
			margin-top: 60px;
			margin-bottom: 40px;

			.selTabClass {
				background: linear-gradient(180deg, #2779FF 0%, #75AAFF 100%);
				box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
				border-radius: 8px;
				color: white;

				span {
					display: block;
					width: 53px;
					height: 4px;
					background-color: white;
					position: relative;
					top: -8px;
					border-radius: 4px;
					left: 73px;
				}
			}

			div {
				width: 200px;
				height: 53px;
				line-height: 48px;
				cursor: pointer;
			}
		}

		.descTitle {
			font-size: 32px;
			color: #333;
			margin: 30px 0;
		}

		.descBox {
			font-size: 18px;
			color: #666666;
		}
	}

	// 选择我们的理由
	.liyouBox {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: 40px;

		.ly_title {
			font-size: 32px;
			color: #333
		}

		.ly_desc {
			font-size: 20px;
			color: #666;
			margin-top: 20px;
		}

		.ly_lisBox {
			display: flex;
			justify-content: center;
			margin-top: 30px;

			.ly_lis {
				margin: 0 10px;
				position: relative;
				border-radius: 20px;
				cursor: pointer;

				.ly_contxt {
					position: absolute;
					bottom: 60px;
					text-align: center;
					width: 100%;

					div:nth-child(1) {
						font-size: 32px;
						color: #333;
					}

					div:nth-child(2) {
						font-size: 18px;
						color: #666;
						margin-top: 20px;
					}
				}
			}

			img {
				width: 360px;
				height: 470px
			}
		}
	}

	.bgImgClass {
		width: 100%;
		height: 684px;
		display: block;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.switchBgStyle {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0
	}

	.con_contentBox {
		opacity: 1;
		animation: fade 1s linear;
	}

	@keyframes fade {
		0% {
			opacity: 0
		}

		100% {
			opacity: 1
		}
	}

	.banner_Box {
		width: 100%;
		display: block;
		position: relative;
		right: 0;
		animation: showBanner 1.5s linear;
	}

	@keyframes showBanner {
		0% {
			opacity: 0
		}

		100% {
			opacity: 1
		}
	}
	
	#companyContxtBox{
		#companyInfoBox{
			background-image: url('../static/images/companyInfobg.png');
			background-size: 100% 100%;height: 660px;
		}
		#lxwmBox{
			display: flex;text-align: left;width: 1000px;margin: 0 auto;
			padding: 50px 0;
			.lxwm_title{
				margin-bottom: 12px;font-size: 36px;
				text-shadow: 0 -20px 1px #EAEAEA;
			}
			.lxwm_name{
				border-bottom: 5px solid;border-image: linear-gradient(to right, #2779FF, #75AAFF) 4;
				padding-bottom: 12px;font-size: 30px;color: #333;
			}
			
			.lxwm_lisBox{
				font-size: 24px;display: flex;align-items: center;
				margin-top: 30px;
				img{
					width: 42px;height: 42px;margin-right: 20px;
				}
			}
		}
	}
	
</style>