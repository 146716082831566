<template>
	<div id="headBox">
		<el-row id="headerBox">
			<el-col :span="6">
				<img src="../../static/images/logoImg.png" id="companyLogo">
			</el-col>
			<el-col :span="12">
				<el-menu default-active="1" class="el-menu-demo" menu-trigger='click' mode="horizontal"
					active-text-color='#297BFF' text-color="#333333" :unique-opened='true' ref='menuId' @open='openMenu' @close='closeMenu'>
					<el-menu-item index="1" @click="$router.push('/')">首页</el-menu-item>
					<el-menu-item index="4" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603765346456829952')">使用手册</el-menu-item>
					<el-menu-item index="6" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=2&aid=603454920175124480')">开发文档</el-menu-item>
					<el-menu-item index="7" @click="$router.push('/aboutus')">关于我们</el-menu-item>
					
					<el-submenu index="8" @click.stop="">
						<template slot="title">演示中心</template>
						<div style="text-align: left;padding: 15px 30px;display: flex;border-radius: 10px;">
							<div style="padding-right: 50px;">
								<div style="font-size: 14px;color: #666;margin-bottom: 10px;">演示站</div>
								<div style="font-size: 14px;">
									<div style="margin-top: 10px;">
										<a href="http://localhost:8080/" target="_blank" style="text-decoration: none;color: #333;font-weight: bold">H5客户端</a>
									</div>
									<div style="margin-top: 10px;">
										<a href="http://localhost:8080/" target="_blank" style="text-decoration: none;color: #333;font-weight: bold">PC后台</a>
									</div>
								</div>
							</div>
							<div>
								<div style="font-size: 14px;color: #666;margin-bottom: 10px;">微信小程序</div>
								<div style="margin-top: 10px;">
									<img src="../../static/images/jy_imgCode.jpg" alt="" style="width: 100px;height: 100px">
								</div>
							</div>
						</div>
					</el-submenu>
				</el-menu>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() { 
			return {
			}
		},
		mounted() {
		},
		methods: {
			toWebUrl(url){
				window.open(url,'_blank')
			},
			openMenu(e){
				if(e == 8){
					setTimeout(()=>{
						window.addEventListener('click',this.closeDef)
					},200)
				}
			},
			closeMenu(e){
				window.removeEventListener('click', this.closeDef);
			},
			closeDef(){
				this.$refs.menuId.close(this.$refs.menuId._data.openedMenus[0])
				window.removeEventListener('click', this.closeDef);
			}
		}
	}
</script>

<style>
	.el-menu--popup-bottom-start{
		border-radius: 6px !important;
	}
	.el-drawer__header{
		margin-bottom: 0;
	}
	.el-menu-demo {
		border-bottom: none !important;
	}

	.el-menu-item,
	.el-submenu__title {
		border-bottom: none !important;
	}

	.el-menu-item:hover {
		color: #409EFF !important;
		font-weight: bold;
	}

	.el-submenu__title:hover {
		color: #409EFF !important;
		font-weight: bold;
	}

	.el-menu--horizontal>.el-menu-item {
		height: 40px !important;
		line-height: 50px !important;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title {
		height: 40px !important;
		line-height: 50px !important;
	}
</style>
<style lang="scss" scoped>
	#headerBox {
		min-width: 1600px;
	}
	#headBox {
		background: #FFFFFF;
		padding: 10px;
		z-index: 99;
		position: relative;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

		#companyLogo {
			width: 270px;
			height: 47px
		}
	}
</style>