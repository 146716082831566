<template>
	<div style="position: relative;">
		<img src="../../static/images/footerBgimg.png" style="width: 100%;height: 385px" alt="">
		<div id="footerBox">
			<div id="contactBox">
				<img src="../../static/images/erweima.png">
				<div>扫码添加微信</div>
			</div>
			<div class="f_lisBox">
				<div class="f_title">地址</div>
				<div class="f_txt">河北省石家庄市长安区石纺路95号保利广场（北区）H2办公楼1514</div>
				
				<div class="f_title" style="margin-top: 30px;">官方电话</div>
				<div class="f_txt">0311-67898888</div>
			</div>
			<div class="f_lisBox">
				<div class="f_title">产品</div>
				<div class="f_txt">玖优精选商城</div>
			</div>
			<div class="f_lisBox">
				<div class="f_title">文档手册</div>
				<div class="f_txt" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603765346456829952')">系统参数</div>
				<div class="f_txt" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603777538694578176')">会员管理</div>
				<div class="f_txt" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603776607735250944')">权限管理</div>
			</div>
		</div>
		
		<div style="position: absolute;bottom: 20px;width: 100%;color: #666;cursor: pointer;" @click="toWebUrl('https://beian.miit.gov.cn/#/Integrated/index')">
			玖优精选商城系统 | 备案：冀ICP备2022006314号-1
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		methods:{
			toWebUrl(url){
				window.open(url,'_blank')
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	#footerBox{
		position: absolute;top: 0;padding: 30px 10%;padding-top: 80px;
		display: flex;justify-content: center;width: 100%;box-sizing: border-box;
		#contactBox{
			background-color: white;padding: 20px 30px;border-radius: 10px;
			font-size: 14px;color: #666;
			img{
				width: 100px;height: 100px;margin-bottom: 10px;
			}
		}
		.f_lisBox{
			text-align: left;padding: 0 40px;
			.f_title{
				font-size: 18px;color: #333;margin-bottom: 20px;font-weight: bold;
			}
			.f_txt{
				font-size: 14px;color: #666;cursor: pointer;
			}
			.f_txt:hover{
				color: red;
			}
		}
	}
</style>