<template>
	<div id="phone_home">

		<!-- 标题栏 -->
		<div id="headbox">
			<div style="width: 50rem;"><img src="../../static/images/logoImg2.png" alt=""></div>
			<div style="width: 275rem;display: flex;justify-content: end;font-size: 13rem;">
				<div style="width: 40rem;">首页</div>
				<div style="width: 65rem;" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603765346456829952')">使用手册</div>
				<div style="width: 65rem;">更新日志</div>
				<div style="width: 65rem;">关于我们</div>
			</div>
			<div style="width: 50rem;" @click="showNav">
				<i class="el-icon-s-operation" style="font-size: 25rem;"></i>
			</div>
		</div>

		<!-- 轮播图 -->
		<el-carousel height="270rem" :interval="5000">
			<el-carousel-item class="switchBox">
				<img src="../../static/images/phone/switchImg1.png" alt="">
			</el-carousel-item>
			<el-carousel-item class="switchBox">
				<img src="../../static/images/phone/switchImg2.png" alt="">
			</el-carousel-item>
		</el-carousel>

		<!-- 项目类型切换框 -->
		<div id='proTabBox'>
			<div class="pro_title">玖优精选商城系统</div>
			<div class="pro_desc">功能全面、操作便捷的电商产品服务</div>
			<div id="shopTypeBox">
				<div :class="typeIndex == 0?'selShopTypeClass':''" @click="typeIndex = 0">
					微信小程序
				</div>
				<div :class="typeIndex == 1?'selShopTypeClass':''" @click="typeIndex = 1">
					PC商家后台
				</div>
				<div :class="typeIndex == 2?'selShopTypeClass':''" @click="typeIndex = 2">
					H5商家后台
				</div>
			</div>
			<div>
				<img v-show="typeIndex == 0" src="../../static/images/phone/wx_banner.png" alt="">
				<img v-show="typeIndex == 1" src="../../static/images/phone/pc_banner.png" alt="">
				<img v-show="typeIndex == 2" src="../../static/images/phone/wx_banner.png" alt="">
			</div>
		</div>

		<!-- 产品说明 -->
		<div id="productDescBox">
			<div v-for="(item,ind) in projuctList" class="productLisBox">
				<img :src="item.bgUrl" alt="" class="bgImg">
				<div class="productConBox">
					<div class="productTitle" v-if="(ind+1)%2 == 0" style="display: flex;justify-content: end;">
						<span>{{'0'+(ind+1)}}</span>
						<div style="right: 40rem;">{{item.title}}</div>
					</div>
					<div class="productTitle" v-if="(ind+1)%2 != 0" style="display: flex;">
						<span>{{'0'+(ind+1)}}</span>
						<div style="left: 40rem;">{{item.title}}</div>
					</div>
					<div style="display: flex;flex-wrap: wrap;">
						<div class="product_small_title">
							<div v-for="(con,index) in item.children" @click="item.selIndex = index"
								:class="item.selIndex == index?'selTabClass':''">
								{{con.title}}
								<span></span>
							</div>
						</div>
					</div>
					<div style="width: 100%;display: flex;justify-content: center;">
						<img :src="item.img" alt="" v-if="(ind+1)%2 == 0">
						<div style="width: 200rem">
							<div style="text-align: left;font-weight: bold;margin-bottom: 12rem;">
								{{item.children[item.selIndex].desc}}</div>
							<div v-html="item.children[item.selIndex].context"
								style="text-align: left;font-size: 14rem;color: #666;">
							</div>
						</div>
						<img :src="item.img" alt="" v-if="(ind+1)%2 != 0">
					</div>
				</div>
			</div>
		</div>

		<!-- 选择我们的理由 -->
		<div id="liyouBox">
			<div class="pro_title">选择我们的理由</div>
			<div class="pro_desc">实践积累，经验丰富，专业团队</div>
			<div class="ly_lisBox">
				<div class="ly_lis">
					<img src="../../static/images/desc05.png" alt="">
					<div class="ly_contxt">
						<div>多终端</div>
						<div>小程序 + H5端 + PC端</div>
					</div>
				</div>
				<div class="ly_lis">
					<img src="../../static/images/desc06.png" alt="">
					<div class="ly_contxt">
						<div>在线客服</div>
						<div>沟通便捷，反馈快</div>
					</div>
				</div>
				<div class="ly_lis">
					<img src="../../static/images/desc07.png" alt="">
					<div class="ly_contxt">
						<div>资料丰富</div>
						<div>使用手册以及部署文档等</div>
					</div>
				</div>
				<div class="ly_lis">
					<img src="../../static/images/desc08.png" alt="">
					<div class="ly_contxt">
						<div>定制开发</div>
						<div>个性化定制，专属系统</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 荣誉证书 -->
		<div>
			<div class="pro_title">软著证书</div>
			<div class="pro_desc">软著登记证书，实力有保证</div>
			<div id="zhengshuImgLis">
				<img src="../../static/images/zhengshu1.png"/>
				<img src="../../static/images/zhengshu2.png"/>
				<img src="../../static/images/zhengshu3.png"/>
				<img src="../../static/images/zhengshu4.png"/>
			</div>
		</div>
		
		<!-- 关于我们 -->
		<div id="companyInfoBox">
			<div class="com_title">联系我们</div>
			<div class="com_desc">河北玖御信息科技有限公司</div>
			<div class="com_map">
				<baidu-map style="height: 160rem;" @ready='handler'></baidu-map>
			</div>
			<div class="com_lis">
				<img src="../../static/images/icon1.png" alt="">
				<div>河北省石家庄市长安区石纺路95号保利广场（北区）H2办公楼1514</div>
			</div>
			<div class="com_lis">
				<img src="../../static/images/icon2.png" alt="">
				<div>0311-67898888</div>
			</div>
			
		</div>

		<div id="footerBox">
			<el-collapse v-model="activeName">
				<el-collapse-item title="产品" name="1">
					<div class="f_lisBox">
						<div>玖优精选商城</div>
					</div>
				</el-collapse-item>
				<el-collapse-item title="文档手册" name="2">
					<div class="f_lisBox">
						<div class="f_txt" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603765346456829952')">系统参数</div>
						<div class="f_txt" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603777538694578176')">会员管理</div>
						<div class="f_txt" @click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603776607735250944')">权限管理</div>
					</div>
				</el-collapse-item>
				<el-collapse-item title="友情链接" name="4">
					<div class="f_lisBox">
						<div>玖御科技有限公司官网</div>
					</div>
					
				</el-collapse-item>
				<el-collapse-item title="联系我们" name="5">
					<div class="f_lisBox">
						<div>地址：河北省石家庄市长安区石纺路95号保利广场（北区）H2办公楼1514</div>
						<div>电话：0311-67898888</div>
						<div>
							<img src="../../static/images/erweima.png" style="width: 72rem;height: 72rem;border-radius: 4rem;border: 4rem solid white;" alt="">
						</div>
						<div>扫码添加微信</div>
					</div>
					
				</el-collapse-item>
				
			</el-collapse>
			<div style="font-size: 14rem;color: #666;padding: 10rem;">
				<div>玖优精选商城系统</div>
				<div>备案：冀ICP备2022006314号-1</div>
			</div>
		</div>


		<!-- 导航菜单 -->
		<el-drawer :visible.sync="showNavBox" size='200rem' :show-close='false' :withHeader='false'>
			<el-menu class="el-menu-vertical-demo">
				<el-menu-item index="1">首页</el-menu-item>
				<el-menu-item index="4"
					@click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=3&aid=603765346456829952')">使用手册</el-menu-item>
				<el-menu-item index="6"
					@click="toWebUrl('https://doc.get9.cn/?g=Doc&m=Article&a=index&id=2&aid=603454920175124480')">开发文档</el-menu-item>
				<el-menu-item index="7">关于我们</el-menu-item>
				<el-submenu index='8'>
					<template slot="title">演示中心</template>
				</el-submenu>
			</el-menu>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showNavBox: false,
				typeIndex: 0,
				activeName: ['1','2','3','4','5'],
				projuctList: [{
					bgUrl: require('../../static/images/phone/productBg1.png'),
					title: '多种运营模式',
					img: require('../../static/images/desc01.png'),
					selIndex: 0,
					children: [{
						title: '自营模式',
						desc: '建立自己的供应链生态系统',
						context: `自主决定产品的设计、生产、销售和服务等环节，提高产品的竞争力；<br>
								直接面对终端消费者，省去中间环节的利润分配，从而获得更多的利润空间；<br>
								传递自己的品牌理念和价值观，增加品牌的商业价值；<br>
								信誉保障，防止假冒伪劣，增加客户粘性；`
					}, {
						title: '入驻模式',
						desc: '第三方平台上开设自己的店铺',
						context: `成熟的运营模式，商家只需要简单了解入驻流程和规定，即可上手经营。<br>
								这使得商家能够更快地进入市场，减少前期准备的时间和成本；<br>
								自带流量，商家可以利用平台的品牌效应和流量优势，快速吸引潜在客户，提升销售额；<br>
								节约成本，无需自行维护和推广网站，节省初始团队的人力和时间成本。<br>
								此外，商家还可以利用平台的营销工具和推广资源，提升产品曝光度和销售效果；`
					}, {
						title: '混合模式',
						desc: '综合自营模式和入驻模式',
						context: '更加灵活自由；<br>自营+分销；<br>更好的应对处理问题以提高销售额'
					}]
				}, {
					bgUrl: require('../../static/images/phone/productBg2.png'),
					title: '多维度数据分析',
					img: require('../../static/images/desc02.png'),
					selIndex: 0,
					children: [{
						title: '销量数据',
						desc: '销量数据统计',
						context: `月度商品销量统计图：可查看销售量、退款量以及退货量；<br>
								商品年度销量统计：可查看一年中每个月的情况统计；`
					}, {
						title: '总营业额',
						desc: '营业额数据统计',
						context: `总营业额统计图：可查看迄今为止的总营业额；`
					}, {
						title: '订单',
						desc: '订单数据',
						context: `总订单数：可查看迄今为止的总订单数量；<br>
								月订单数：可查看当月订单数量；<br>
								订单分类数据：可查看成交率、待支付率和售后率占比；`
					}]
				}, {
					bgUrl: require('../../static/images/phone/productBg3.png'),
					title: '多种营销工具',
					img: require('../../static/images/desc03.png'),
					selIndex: 0,
					children: [{
						title: '裂变拉新',
						desc: '营销活动',
						context: `超值拼团：多人拼单促活；<br>
								限时秒杀：超值低价吸引客户流量和处理存货并增加销量；`
					}, {
						title: '互动活动',
						desc: '签到积分活动',
						context: `积分系统：每日签到或者完成任务获得积分；<br>
								积分商城：积分可以直接购买积分商城内的商品；`
					}, {
						title: '拓客引流',
						desc: '推流分享',
						context: `直播卖货：可开启直播卖货，加入直播折扣等营销方式来吸引客户下单，分享直播间至好友；<br>
								短视频营销：短视频宣发吸引客户兴趣，进行店铺引流，促进订单成交，分享短视频；`
					}]
				}, {
					bgUrl: require('../../static/images/phone/productBg4.png'),
					title: '多方位服务体系',
					img: require('../../static/images/desc04.png'),
					selIndex: 0,
					children: [{
						title: '多个终端',
						desc: '多端联动',
						context: `微信小程序用户端 + H5端商家后台 + PC商家后台`
					}, {
						title: '专业客服',
						desc: '专业客服',
						context: `24小时为您服务；<br>
								客户咨询解答：提供详细的信息和解释，帮助客户更好地了解和使用产品；<br>
								投诉处理与解决：准确、迅速地回答客户关于产品或服务的各种问题；<br>
								售后服务与支持：提供售后服务，确保客户在使用产品过程中得到及时的支持和帮助；<br>
								耐心和细心：确保每一个问题都得到妥善处理；`
					}, {
						title: '定制开发',
						desc: '1v1专属定制',
						context: `针对性强：适应企业规模发展与产品管理的需求变化；<br>
								使用方便：完全根据使用者的流程所开发，节省经济成本和人力成本；<br>
								效率提高：定制软件具有业务针对性，开发按照企业业务流程来定制，大幅提高效率；`
					}]
				}]
			}
		},
		mounted() {
			
		},
		methods: {
			handler({Bmap,map}){
				this.map = map;
				// 初始化地图,设置中心点坐标
				var point = new BMap.Point(114.5336401462555,38.07448486757763);
				map.centerAndZoom(point, 14);
				// 添加鼠标滚动缩放
				map.enableScrollWheelZoom();
				var marker = new BMap.Marker(point);        // 创建标注   
				map.addOverlay(marker);                     // 将标注添加到地图中
			},
			showNav(){
				this.showNavBox = true
			},
			toWebUrl(url) {
				this.showNavBox = false
				window.open(url, '_blank')
			}
		}
	}
</script>

<style>
	.el-collapse-item__header{
		background-color: rgba(255,255,255, 0.24) !important;
		color: #1F4D9A !important;
		font-size: 14rem !important;
		font-weight: bold !important;
		padding-left: 10rem;
		box-sizing: border-box;
		
	}
	.el-collapse-item__wrap{
		background-color: transparent !important;
	}
	.el-collapse-item__content{
		padding-bottom: 0 !important;
		font-size: 14rem !important;
	}
</style>
<style scoped lang="scss">
	.switchBox {
		img {
			width: 100%;
			height: 100%
		}
	}

	#proTabBox {
		width: 100%;

		img {
			width: 100%;
			height: 220rem;
			display: block;
		}
	}

	.pro_title {
		font-weight: bold;
		margin-bottom: 10rem;
		margin-top: 20rem;
	}

	.pro_desc {
		font-size: 12rem;
		color: #666;
	}

	// 标题栏
	#headbox {
		background-color: white;
		height: 50rem;
		display: flex;
		align-items: center;
		font-weight: bold;

		img {
			width: 40rem;
			height: 40rem
		}
	}

	#productDescBox {
		.productLisBox {
			position: relative;

			img {
				width: 100%;
			}

			.bgImg {
				display: block;
				position: absolute;
				width: 100%;
				height: 100%
			}

			.productConBox {
				text-align: center;
				position: relative;
				width: 100%;
				padding: 10rem;
				box-sizing: border-box;

				img {
					width: 128rem;
					height: 115rem;
				}

				.productTitle {
					font-size: 16rem;
					color: #333;
					font-weight: bold;
					position: relative;
					padding-bottom: 30rem;

					span {
						display: block;
						font-size: 58rem;
						color: #E2E2E2;
						position: absolute;
						top: -4rem;
					}

					div {
						position: relative;
						z-index: 99;
						top: 22rem;
					}
				}

				.product_small_title {
					width: 100%;
					display: flex;
					justify-content: center;
					margin: 14rem 0;

					div {
						margin: 6rem 14rem;
						padding: 4rem 12rem;
						font-size: 15rem;
					}

					.selTabClass {
						background: linear-gradient(180deg, #2779FF 0%, #75AAFF 100%);
						box-shadow: 0px 3rem 6rem 1rem rgba(0, 0, 0, 0.16);
						border-radius: 8rem;
						color: white;

						span {
							display: block;
							width: 30rem;
							height: 3rem;
							background: linear-gradient(90deg, #FFFFFF 0%, #9EC3FF 100%);
							border-radius: 3rem;
							margin-top: 4rem;
							position: relative;
							left: 14rem;
						}
					}
				}
			}
		}
	}

	#liyouBox {
		background-image: url('../../static/images/phone/productBg5.png');
		background-size: 100% 100%;

		.ly_lisBox {
			display: flex;
			justify-content: center;
			margin-top: 30rem;
			flex-wrap: wrap;

			.ly_lis {
				margin: 0 10rem;
				position: relative;
				border-radius: 20rem;
				cursor: pointer;

				.ly_contxt {
					position: absolute;
					bottom: 20rem;
					text-align: center;
					width: 100%;

					div:nth-child(1) {
						font-size: 15rem;
						color: #333;
					}

					div:nth-child(2) {
						font-size: 12rem;
						color: #666;
						margin-top: 6rem;
					}
				}
			}

			img {
				width: 150rem;
				height: 180rem
			}
		}
	}

	// 选择系统类型
	#shopTypeBox {
		display: flex;
		justify-content: center;

		div {
			padding: 4rem 10rem;
			font-size: 14rem;
			margin-bottom: 14rem;
			border: 1px solid #666666;
			border-radius: 40rem;
			color: #666;
			margin: 10rem;cursor: pointer;
		}

		.selShopTypeClass {
			background: linear-gradient(90deg, #8DB8FF 0%, #297BFF 100%);
			box-sizing: border-box;
			border-radius: 40rem;
			color: white;
			border: none;
			box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		}
	}
	#zhengshuImgLis{
		width: 100%;overflow-x: scroll;white-space:nowrap;
		img{
			width:200rem;height: 240rem;margin: 10rem 10rem 0 10rem;
		}
	}
	#footerBox {
		background-image: url('../../static/images/phone/footerBgimg.png');
		background-size: 100% 100%;text-align: left;
		.f_lisBox{
			padding: 10rem;box-sizing: border-box;
		}
	}
	
	#companyInfoBox{
		padding: 30rem 10rem;text-align: left;box-sizing: border-box;
		.com_title{
			font-size: 24rem;text-shadow: 0 -15rem #eaeaea;
		}
		.com_desc{
			padding-bottom: 10rem;margin-top: 14rem;
			border-bottom: 4rem solid #2779FF;
		}
		.com_map{
			width: 100%;height: 160rem;background-color: #ccc;margin-top: 15rem;
		}
		.com_lis{
			display: flex;align-items: center;margin-top: 20rem;
			img{
				width: 26rem;height: 26rem;margin-right: 10rem;
			}
		}
	}
	
</style>