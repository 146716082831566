<template>
	<div>
		<div id="contactBox">
			<div class="conIcon" @click.stop="isShow == 1?isShow = 0:isShow = 1">
				<img v-show="isShow != 1" src="../../static/images/contact03.png" alt="">
				<img v-show="isShow == 1" src="../../static/images/contact01.png" alt="">
			</div>
			<div class="hrLine"></div>
			<div class="conIcon" @click.stop="isShow == 2?isShow = 0:isShow = 2">
				<img v-show="isShow != 2" src="../../static/images/contact04.png" alt="">
				<img v-show="isShow == 2" src="../../static/images/contact02.png" alt="">
			</div>
			<div class="hrLine"></div>
			<div class="conIcon">
				<img src="../../static/images/contact05.png" alt="">
			</div>
		</div>
		<div class="alertBox" :style="{'right':isShow == 2?'40px':'-100%'}" style="bottom: 340px;">
			<img src="../../static/images/erweima.png" alt="">
		</div>
		<div class="alertBox" :style="{'right':isShow == 1?'40px':'-100%'}">
			<span>0311-67898888</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShow: 0
			}
		},
		mounted() {
			window.addEventListener('click',this.closeDef)
		},
		methods: {
			closeDef(){
				if(this.isShow != 0){
					this.isShow = 0
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#contactBox {
		width: 48px;
		height: 180px;
		background: #FFFFFF;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 30px;
		position: fixed;
		right: 20px;
		bottom: 300px;
		z-index: 999;

		.conIcon {
			height: 60px;
			cursor: pointer;

			img {
				width: 30px;
				height: 30px;
				margin-top: 15px;
			}
		}

		.hrLine {
			width: 24px;
			height: 1px;
			background-color: #333;
			margin: 0 auto;
		}
	}

	.alertBox {
		background: linear-gradient(180deg, #2779FF 0%, #75AAFF 100%);
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		color: white;
		border-radius: 12px;
		position: fixed;
		bottom: 420px;
		z-index: 990;
		padding: 20px 40px;
		padding-right: 50px;
		transition: all 0.4s;

		img {
			width: 82px;
			height: 82px;
			border: 8px solid white;
			border-radius: 4px
		}
	}
</style>