<template>
	<div id="app">
		<el-header style="padding: 0;" id="headerBox">
			<header-dom></header-dom>
		</el-header>
		<el-main style="width: 100%;padding: 0;overflow: hidden;">
			<router-view />
		</el-main>
		<el-footer style="padding: 0;" id="footerBox">
			<footer-dom></footer-dom>
		</el-footer>
	</div>
</template>

<script>
	import headerDom from './components/base/headerDom.vue'
	import footerDom from './components/base/footerDom.vue'
	export default {
		data() {
			return {
				
			}
		},
		components:{
			headerDom,
			footerDom
		}
	}
</script>

<style lang="scss" scoped>
	*{
		margin: 0;padding: 0;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;font-size: 16px;
		color: #2c3e50;
	}
	#headerBox{
		display: none;
	}
	#footerBox{
		display: none;
	}
	
	// 媒体查询
	@media only screen and (max-width:900px){
		#headerBox {
			display: none;
		}
		#footerBox{
			display: none
		}
	}
	@media only screen and (min-width:900px){
		#headerBox {
			display: block;
		}
		#footerBox{
			display: block
		}
	}
</style>