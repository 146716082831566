import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'index',
	component: HomeView
},{
	path: '/aboutus',
	name: 'aboutus',
	component: () => import('../views/aboutus.vue')
}]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router